import React from 'react';

const Career = () => {
    return (
        <div className="pt-32 flex flex-col pb-10 goldbg">
        <div className="text-5xl lg:text-6xl self-center s2s-header text-center">
          Career Opportunities
        </div>
      </div>
    );
}

export default Career;
