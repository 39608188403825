import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

// Main List
const Ul = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;

  li {
    padding: 22px 15px;
    font-size: medium;
  }

  @media (min-width: 1023px) {
    display: none;
  }

  @media (max-width: 1023.1px) {
    flex-flow: column nowrap;
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 50vw;
    margin: 0;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    background-color: black;
    z-index: 1;
  }
`;
// About List
const Aboutlist = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;

  li {
    padding: 22px 15px;
    font-size: medium;
  }

  @media (min-width: 1023px) {
    display: none;
  }

  @media (max-width: 1023.1px) {
    flex-flow: column nowrap;
    position: fixed;
    transform: ${({ aboutOpen }) =>
      aboutOpen ? "translateX(0)" : "translateX(200%)"};
    top: 0;
    left: 0;
    height: 100vh;
    width: 50vw;
    margin: 0;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    background-color: black;
    text-align: center;
  }
`;

// Programs List
const Programlist = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;

  li {
    padding: 22px 15px;
    font-size: medium;
  }

  @media (min-width: 1023px) {
    display: none;
  }

  @media (max-width: 1023.1px) {
    flex-flow: column nowrap;
    position: fixed;
    transform: ${({ programsOpen }) =>
      programsOpen ? "translateX(0)" : "translateX(200%)"};
    top: 0;
    left: 0;
    height: 100vh;
    width: 50vw;
    margin: 0;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    background-color: black;
    text-align: center;
  }
`;









export default function NavMenu(props) {
  const setOpen = props.setOpen;
  const setAboutOpen = props.setAboutOpen;
  const setProgramsOpen = props.setProgramsOpen
  const open = props.open;
  const aboutOpen = props.aboutOpen;
  const programsOpen = props.programsOpen


  function handleMenu() {
    setOpen(!open);
    setAboutOpen(false);
    setProgramsOpen(false);
  }

  function closeMenus() {
    setOpen(false);
    setAboutOpen(false);
    setProgramsOpen(false);
  }

  function handleAbout() {
    setAboutOpen(!aboutOpen)
    setProgramsOpen(false);
  }

  function handlePrograms() {
    setProgramsOpen(!programsOpen);
    setAboutOpen(false)
  }

  return (
    <div>
      <Ul setAboutOpen={props.setAboutOpen} open={props.open}>
        <NavLink exact to={"/"}>
          <li onClick={() => handleMenu()} className="gold  s2s-alt">
            Home
          </li>
        </NavLink>

        <li
          onClick={() => handleAbout()}
          className="gold s2s-alt"
        >
          <FontAwesomeIcon
          icon={faChevronLeft}></FontAwesomeIcon> About Us
        </li>

        <NavLink exact to={"/mission"}>
          <li onClick={() => handleMenu()} className="gold s2s-alt">
            Mission
          </li>
        </NavLink>

        
          <li onClick={() => handlePrograms()} className="gold s2s-alt">
          <FontAwesomeIcon
          icon={faChevronLeft}></FontAwesomeIcon>  Programs
          </li>
        

        <NavLink exact to={"/contact"}>
          <li onClick={() => handleMenu()} className="gold s2s-alt">
            Contact
          </li>
        </NavLink>

        <NavLink exact to={"/donate"}>
          <li onClick={() => handleMenu()} className="gold s2s-alt">
            Donate
          </li>
        </NavLink>
      </Ul>


      <Aboutlist aboutOpen={props.aboutOpen}>
          <div className="gold text-xl s2s-alt font-bold pb-3">About Us</div>
        <NavLink exact to={"/about"}>
          <li onClick={() => closeMenus()} className="gold  s2s-alt">
            What We DO
          </li>
        </NavLink>
        <NavLink exact to={"/ceo"}>
          <li onClick={() => closeMenus()} className="gold  s2s-alt">
            CEO
          </li>
        </NavLink>
        <NavLink exact to={"/board"}>
          <li onClick={() => closeMenus()} className="gold  s2s-alt">
            Board of Directors
          </li>
        </NavLink>
        <NavLink exact to={"/staff"}>
          <li onClick={() => closeMenus()} className="gold  s2s-alt">
            Staff
          </li>
        </NavLink>
        <a target="_blank" rel="noopener noreferrer" exact href="https://soul2soulz.com/">
          <li onClick={() => closeMenus()} className="gold  s2s-alt">
            Soul2Soulz.com
          </li>
          </a>
      </Aboutlist>

      <Programlist programsOpen={props.programsOpen}>
          <div className="gold text-xl s2s-alt font-bold pb-3">Programs</div>
        <NavLink exact to={"/development"}>
          <li onClick={() => closeMenus()} className="gold  s2s-alt">
            Youth Development
          </li>
        </NavLink>
        <NavLink exact to={"/education"}>
          <li onClick={() => closeMenus()} className="gold  s2s-alt">
            Education
          </li>
        </NavLink>
        <NavLink exact to={"/parent-workshop"}>
          <li onClick={() => closeMenus()} className="gold  s2s-alt">
            Parent Workshops
          </li>
        </NavLink>
        <NavLink exact to={"/teacher-workshop"}>
          <li onClick={() => closeMenus()} className="gold  s2s-alt">
            Teacher Workshop
          </li>
        </NavLink>
      </Programlist>



    </div>
  );
}
