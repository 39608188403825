import React, { useEffect } from 'react';
import { MemberData } from "./MemberData"

const Staff = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      });
      
    let staffMembers = MemberData.filter((staff) => staff.position === "staff")
    return (
    
        <div className="pt-32 lg:px-20 xl:px-16 flex flex-col pb-10 goldbg">
        <div className="text-5xl lg:text-6xl self-center s2s-header">
          Staff
        </div>

        
        {staffMembers.map((staff, index) => (
            <div key={index}>
                <div  className="flex flex-col-reverse lg:grid lg:grid-cols-4 self-start p-3">      
          <div className="col-span-3 p-3">
            <div className="s2s-text text-xl">{staff.name}</div>
            <div className="s2s-text text-lg pb-2">{staff.title}</div>
            <div className="s2s-text">
              {staff.summary}
            </div>
          </div>
          <div className="p-2">
            <img className="w-full membersPadding" src={staff.image} alt="" />
          </div>
          </div> 
        {index +1 !== staffMembers.length ? <hr className="mx-8"/> : ""}
          </div>
        ))}
      </div>
    );
}

export default Staff;