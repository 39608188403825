import React, { useEffect } from "react";
import donate from "../images/FutureLeader.jpg";
import smile from "../images/AmazonSmile.png";
import donateButton from "../images/donate.png"

const Donate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="flex flex-col pt-32 pb-5 goldbg">
      <div className="text-5xl lg:text-6xl self-center s2s-header">Donate</div>
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="p-10 flex justify-center">
          <div>
            <img className="rounded max-h-seventy" src={donate} alt="" />
          </div>
        </div>

        <div className="flex flex-col self-center p-3 justify-center">
          <div className="flex justify-center flex-col mt-4 px-5">
            <div className="text-center s2s-text">
              Making a donation will help support the work we do with youth.
            </div>
            <div className="text-center s2s-text mt-4">501C3</div>
            <div className="text-center s2s-text">
              Federal Tax Exempt
            </div>
            <div className="text-center s2s-text">
              Nonprofit Organization
            </div>
            <div className="gold p-2 rounded-full s2s-text self-center cursor-pointer text-lg">
              <form
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_top"
              >
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="C5HSQLKLNJXJU"
                />
                <input
                  type="image"
                  className="h-40 w-40"
                  src={
                    donateButton
                  }
                  border=""
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                />
                <img
                  alt=""
                  border="0"
                  src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>
            </div>
          
          <div className="flex flex-col text-black s2s-text text-sm text-center">
            <div>
              Do you shop on Amazon? If so please go through our link,
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://smile.amazon.com/gp/chpf/homepage/ref=smi_chpf_redirect?ie=UTF8&ein=74-3240601&ref_=smi_ext_ch_74-3240601_cl"
              > smile.amazon.com/ch/74-3240601 </a>
              shop as usual and Amazon will donate a small percentage of your
              purchase to Soul2Soulz, Inc. costing you nothing.
            </div>
            <span className="flex justify-center">
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://smile.amazon.com/gp/chpf/homepage/ref=smi_chpf_redirect?ie=UTF8&ein=74-3240601&ref_=smi_ext_ch_74-3240601_cl"
                >
                  <img
                    className="item-center h-24 w-24 rounded my-2"
                    src={smile}
                    alt=""
                  />
                </a>
              </div>
            </span>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donate;
