import React, { useEffect } from "react";
import about from "../images/AboutUs.jpeg";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="goldbg px-5 pt-32 flex flex-col justify-center items-center pb-10">
      <div className="text-5xl lg:text-6xl s2s-header pb-4 text-center">
        What We Do
      </div>
      <div className="flex flex-col lg:flex-row items-start">
        <div>
          <img className="w-full" src={about} alt="" />
        </div>

        <div>
          <div className="about-text-padding s2s-text text-sm sm:text-base">
            Soul2Soulz is an organization dedicated to providing motivational
            tools to help youth reach their full potential. In addition,
            Soul2Soulz offers programs for parents and teachers to better engage
            with students. Partnering with schools, juvenile detention centers,
            youth centers, group homes, independent living facilities and
            colleges to develop workshops and other programs, Soul2Soulz uses
            the powerful medium of film to employ a curriculum, based on
            Collaborative for Academic, Social and Emotional Learning (CASEL),
            the New York State Department of Education-approved standard for
            Social Emotional Learning (SEL). Each of its lessons include the
            five-core social-emotional facets of the CASEL framework:
          </div>
          <div className="pl-4 about-text-list-padding text-sm sm:text-base">
            <li className="s2s-text">Self-Awareness</li>
            <li className="s2s-text">Social Management</li>
            <li className="s2s-text">Social Awareness</li>
            <li className="s2s-text">Relationship Skills</li>
            <li className="s2s-text">
              Responsible Decision-Making Through the impact of storytelling
            </li>
          </div>
        </div>
      </div>
      <div className="pt-3 s2s-text text-base  sm:text-lg text-center">
        Soul2Soulz endeavors to create connections that build positive
        relationships leading to personal growth.
      </div>
    </div>
  );
};

export default About;
