import React, { useEffect } from 'react';

const TeacherWorkshop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
    return (
        <div className="pt-32 page-padding flex flex-col pb-10 goldbg">
        <div className="text-4xl lg:text-6xl self-center s2s-header text-center pb-4">
          Teacher Workshops
        </div>
        <div className="pb-4 s2s-text">
        It’s widely known that teacher and student connection is imperative in
        order for students to perform well in the classroom. Soul2Soulz has
        developed workshops that break down the barriers between students and
        teachers in a way that can foster meaningful relationships and improve
        classroom management.
      </div>
      <div className="pb-2 s2s-text text-xl">Teaching the “challenging” child</div>
      <div className="pb-2 s2s-text">
        Soul2Soulz, Inc will facilitate trainings that support teachers with
        adding new tools to their classroom management such as: Behavior
        Improvement Plans, Functional Behavioral Assessments, Assessment of
        Lagging Skills and Unsolved Problems and/or Restorative Practices.
      </div>
      </div>
    );
}

export default TeacherWorkshop;