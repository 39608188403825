import andrew from "../images/Andrew-Burnett.jpg";
import kerryAnn from "../images/KerryAnn.jpg";
import stephanie from "../images/stephanie.jpeg";
import jason from "../images/Jason.png";
import clifton from "../images/clifton.png";
import wislene from "../images/wislene.png";
import naphtali from "../images/naphtali.jpeg";
import brian from "../images/BrianCreary.png";
import andrewC from "../images/AndrewCreary.JPG";
import hal from "../images/Hal.jpeg"
import jeff from "../images/Jeff.jpeg"
import eric from "../images/Eric.jpeg"

export const MemberData = [
  {
    name: "Jason Anthony Plummer, MSW, MUP",
    position: "board",
    title: "",
    summary:
      "Jason Anthony Plummer serves as Co-Chairperson of Soul2Soulz, Inc Board of Directors. He is also a Ph.D. student in Social Work at the Luskin School of Affairs at the University of California, Los Angeles. Jason’s work focuses on the sociopolitical development of youth, with an emphasis on how race and policy shape the political behavior of youth. In addition to this, Jason is a Lecturer in both the School of Criminal Justice and Criminalistics and Department of Child and Family Studies at California State University. His courses focus on race, resilience, neighborhood context, and the political consequences of policing. He will support Soul2Soulz, Inc by sharing his extensive knowledge in youth development, program and policy development, nonprofit operations, and grant writing.",
    image: jason
  },
  {
    name: "Hal Dumas, MBA",
    position: "board",
    title: "",
    summary:
      "Dumas serves as Co-Chairperson of Soul2Soulz, Inc Board of Directors. He is also an Entrepreneur and business developer, he is the Founder and Managing Partner of Dumas Psychology Collective, LLC, The Brain Train Center, Oxy Pam II and Kelsdge Solution, Inc. Dumas lends his ability to start and grow successful businesses as well as his deep desire to support youth and the community by supporting Soul2Soulz, Inc.",
    image: hal
  },
  {
    name: "Kerry-Ann Elliot-Totten, M.ED.",
    position: "board",
    title: "",
    summary:
      "Kerry-Ann Elliot- Totten serves as Secretary of Soul2Soulz, Inc Board of Directors. She also has over 20 years of experience in education. Kerry-Ann is a former Assistant Principal and English Teacher. She has a passion for youth, their educational and social-emotional development. Kerry-Ann now serves others as an inspirational speaker and empowerment coach. She will support Soul2Soulz, Inc with lesson plan reviews, provide advice on best practices, and what is needed from community-based organizations providing services to schools and other youth facilities.",
    image: kerryAnn
  },
  {
    name: "Jeff Reid, CFA, MBA",
    position: "board",
    title: "",
    summary:
      "Jeff serves as the Treasurer of Soul2Soulz, Inc Board of Directors. He is also a chartered Financial Analyst and Accountant. Jeff is the Assistant Business Manager Administrator for Westbury School District. His past  experience includes Grant Technician for Nassau Community College, interned in the business office of Great Neck Public Schools, Portfolio Analyst, Fund controller, and Tax Auditor.",
    image: jeff
  },
  {
    name: "Andrew Burnett, MBA",
    position: "board",
    title: "",
    summary:
      "Andrew Burnett is an owner of multiple businesses, he is also the Principal and Managing Director of several financial advisory firms, a business consultant, and a former adjunct lecturer in business and entrepreneurship. Education and youth development is important to Andrew and he will utilize his expertise to support the growth of Soul2Soulz, Inc.",
    image: andrew
  },
  {
    name: "Stephanie Rivera, JD",
    position: "board",
    title: "",
    summary:
      "Stephanie Rivera is an Employment Lawyer with a love and drive for youth, community improvement, and development in unserved areas. Stephanie happily supports Soul2Soulz, Inc's human resources, employee development practices, and legal counsel.",
    image: stephanie
  },
  {
    name: "Brian Creary, BS",
    position: "board",
    title: "Chief Management Officer/Facilitator",
    summary:
      "Brian has a Bachelors in Business Administration with a minor in Social Work. He has close to 10 years of experience in Supervision, Management, and Youth Development. Brian worked with youth from underserved communities, living in group homes. He also coached multiple children and teen sports teams, while volunteering his time mentoring adolescent males.  Brian will support Soul2Soulz, Inc with its overall operations and as a Board of Director",
    image: brian
  },


  {
    name: "Brian Creary, BS",
    position: "staff",
    title: "Chief Management Officer/Facilitator",
    summary:
      "Brian has a Bachelors in Business Administration with a minor in Social Work. He has close to 10 years of experience in Supervision, Management, and Youth Development. Brian worked with youth from underserved communities, living in group homes. He also coached multiple children and teen sports teams, while volunteering his time mentoring adolescent males.  Brian will support Soul2Soulz, Inc with its overall operations and as a Board of Director",
    image: brian
  },
  {
    name: "Wislene Charles-Sims, BA",
    position: "staff",
    title: "Director of College, Career and Internship Programming/Facilitator",
    summary:
      "Wislene has over 10 years of experience as a College and Career Counselor. She also has experience as a Training Specialist for Educators, Program Director for S.T.E.A.M, entrepreneurial leadership for youth, and transitional housing for young adult programs. Wislene has a track history of supporting thousands of youth with entering the world of work and getting into college. She is ecstatic to support Soul2Soulz, Inc by continuing to help high school students be prepared for the next stage of their lives.",
    image: wislene
  },
  {
    name: "Clifton Wallace, MA, MS",
    position: "staff",
    title: "Chief Organizational Development Officer/Facilitator",
    summary:
      "Clif has a Masters in Special Education and a Masters in Social-Organizational Psychology. He has been working in the field of education for over 10 years and has used his skills in developing and implementing effective organization processes in educational settings. Clif is excited to continue to utilize both his education and organizational psychology background to continue to develop and innovate what Soul2Soulz, Inc can offer schools and the community. ",
    image: clifton
  },
  
  
  {
    name: "Andrew Creary, BA",
    position: "staff",
    title: "Master Facilitator",
    summary:
      "Andrew Creary has over 15 years of experience working in youth development. He has spent multiple years as a School Dean focused on improving students' behavior. Andrew has also spent many years facilitating college and career prep, social-emotional learning, and character development workshops with youth, young adults, and males in Rikers Island Jail.  Andrew will offer Soul2Soulz, Inc his expertise in providing excellent facilitation of lessons and workshops.",
    image: andrewC
  },
];

export default { MemberData };
