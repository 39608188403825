import React from 'react';
import './css/index.css';
import Main from './components/Main'
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import './css/index.scss'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div >
      <Navbar />
      <Main />
      <Footer />
    </div>
  );
}

export default App;
