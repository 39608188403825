import React, { useEffect } from "react";
import { MemberData } from "./MemberData";

const Board = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  let boardMembers = MemberData.filter((board) => board.position === "board");
  return (
    <div className="pt-32 lg:px-10 xl:px-20 flex flex-col pb-10 goldbg">
      <div className="text-5xl lg:text-6xl self-center s2s-header text-center">
        Board of Directors
      </div>

      {boardMembers.map((member, index) => (
        <div key={index}>
          <div className="flex flex-col-reverse lg:grid lg:grid-cols-4 self-start p-3">
            <div className="col-span-3 p-3">
              <div className="s2s-text text-xl pb-2">{member.name}</div>
              <div className="s2s-text">{member.summary}</div>
            </div>
            
            <div className="p-2">
              <img className="w-full membersPadding" src={member.image} alt="" />
            </div>
          </div>
          {index + 1 !== boardMembers.length ? <hr className="mx-8" /> : ""}
        </div>
      ))}

      
    </div>
  );
};

export default Board;
