import React, { useEffect } from "react";
import soul from "../images/SoulHeadshot.jpeg";

const Ceo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="pt-32 lg:px-10 xl:px-20 flex flex-col pb-10 goldbg">
      <div className="text-5xl lg:text-6xl self-center s2s-header">CEO</div>

      {/* Nicole */}
      <div className="flex flex-col-reverse lg:grid lg:grid-cols-4 items-center p-3">
        <div className="col-span-3 p-3">
          <div className="s2s-text text-xl">Nicole “Soul” Creary, LMSW, MPA</div>
          <div className="s2s-text text-lg">President & CEO</div>
          <div className="s2s-text pl-2">
            <div className="pb-2">
              Soul is the President, CEO and Founder of
              Soul2Soulz, Inc. She created Soul2Soulz, Inc in 2007 under the
              former name Institute for Motivating Adolescents and Nourishing
              Insight-IMANI, Inc responding to the issues facing youth in
              underserved communities. Adolescents feel powerless, and so do
              their parents and educators at times when trying to support them.
              Soul2Soulz, Inc provide in-school day and after school programming
              facilitating workshops, lessons and trainings that engage,
              enlighten, energize and empower youth to live up to their full
              potential.
            </div>

            <div className="pb-2">
              Soul has been working in the social service and field of education
              for over 20 years. She has completed a Bachelors degree in
              Psychology, a Masters degree in Social Work and a Masters degree
              in Public Administration. She has is also a Licensed Master Social
              Worker.
            </div>

            <div className="pb-2">
              Soul is a graduate of the National Urban Fellows, MPA Executive
              Leadership training program. As a part of her education, she
              participated in a fellowship as a Special Assistant to Board of
              Education, Chief of Staff. The duties of this job required her to
              assist with providing strategic development, policy creation,
              conflict mediation in school relations, new program development
              for the Attendance and Truancy department. She also assisted with
              the development of the district-wide strategy to re-enroll out of
              school youth and decrease dropout rates, this was also my
              capstone/thesis focus.
            </div>

            <div className="pb-2">
              Soul has over 13 years’ experience as a Program Director
              supervising in-school, day and after-school programs as an
              employee of community-based organizations with NYC DOE school
              partnerships. She directed successful after school programs,
              Attendance Improvement Dropout Prevention programs, and a Beacon
              Community Center. Soul provided program development, managed
              budgets (which combined was over a million dollars), supervised
              staff, lead staff training and created staff training modules. She
              worked closely with school principals, guidance counselors, social
              workers and teachers to support and strengthen the day school
              programs. Receiving promotions required that Soul manage
              co-located community-based, in-school, after-school programs, and
              managing about 75 staff members, servicing over 800 students. She
              utilize Social Work Interns to provide counseling to students and
              AmeriCorps members to provide tutoring and enrichment support.
               This allowed her programs to provide students with more
              individualized attention while staying within the budget. Soul was
              also responsible for developing and creating community
              partnerships while improving program outcomes.    
            </div>
            <div className="pb-2">
              As a Director of Student Services, it was her job to improve and
              develop the discipline and student service protocol and procedures
              manual. Soul’s approach was always inclusive, the development of
              protocol and procedures included students, staff, and school
              leaders of a charter organization from multiple campuses to
              synthesize our approach to providing student support. I created
              and provided trainings to teachers and school staff on effective
              ways to support our students and improve school culture.
            </div>
            <div className="pb-2">
              Soul is also trained in IIRP Restorative Practice model and is a
              Restorative Practice Educator, she utilizes this training as a
              part of all her program models, to create positive school
              cultures.
            </div>
            <div className="pb-2">
              Soul also has an expertise in writing and directing issue-based
              films tackling challenges relevant to society and utilizes her
              films and other issue based films as a catalyst for
              social-emotional development.
            </div>
          </div>
        </div>
        <div className="p-2 self-start justify-center">
          <img className="w-full membersPadding" src={soul} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Ceo;
