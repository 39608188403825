import React, { useEffect } from "react";

const ParentWorkshop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="pt-32 page-padding flex flex-col pb-10 goldbg">
      <div className="text-4xl lg:text-6xl self-center s2s-header text-center pb-4">
        Parent Workshops
      </div>
      <div className="pb-4 s2s-text">
        Soul2Soulz, Inc affirms the role that parents and caring adults play in
        the successful development of youth. Parents and their child(ren) are
        involved in interactive workshops involving role-plays and skits with
        examples of unwanted outcome and activities that display how to get
        desired outcomes, helping parents to gain the skills of how to talk so
        that their child will listen. Our parent workshops partner parents with
        their children and skilled facilitators in order to support them in
        lovingly guiding their children. The goal of our workshops is to fortify
        the relationship between parent and child to form better communication
        pathways.
      </div>
      <div className="pb-2 s2s-text text-xl">Parenting the College Applicant Workshop</div>
      <div className="pb-2 s2s-text">
        Soul2Soulz, Inc also provides parent support with the college
        application, FASA and financial aid forms.
      </div>     
    </div>
  );
};

export default ParentWorkshop;
