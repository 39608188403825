import React, { useEffect } from "react";
import Phone from "../images/phone_logo.png";
import Facebook from "../images/f_logo.png";
import Instagram from "../images/inst_logo.png";
import Email from "../images/email_logo.png";
import Twitter from "../images/twitter_logo.png";
import ContactImage from "../images/contactpic.jpg";
import logo from "../images/Icon.png"

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="pt-32 flex flex-col justify-center goldbg">
      <div className="text-5xl lg:text-6xl s2s-header m-auto pb-5">
        Contact Us
      </div>
      <div className="md:grid md:grid-cols-2 flex flex-col justify-center items-start">
        <div className="hidden md:flex p-6 items-start">
          <img className="rounded-lg" src={ContactImage} alt="" />
        </div>
        <div className="lg:pl-10 xl:pl-24">

          {/* ***** */}
          {/* Phone */}
          {/* ***** */}
          <a className="underline-none"  href="tel:+3473168444">
            <div className="flex flex-row items-center md:justify-start  p-5 text-black">
              <div className="w-20">
                <img src={Phone} alt="" />
              </div>
              <div className="pl-4">
                <div className="text-2xl s2s-text">Phone</div>
                <div className="s2s-text">(347) 316-8444</div>
              </div>
            </div>
          </a>


          {/* ****** */}
          {/* E-Mail */}
          {/* ****** */}
          <a className="underline-none" href="mailto: email@soul2soulz.org">
            <div className="flex flex-row items-center md:justify-start  p-5 text-black underline-none">
              <div className="w-20">
                <img src={Email} alt="" />
              </div>
              <div className="pl-4">
                <div className="text-2xl s2s-text">Email</div>
                <div className="s2s-text">Email@Soul2Soulz.org</div>
              </div>
            </div>
          </a>


          {/* ******** */}
          {/* FaceBook */}
          {/* ******** */}
          <a className="underline-none" href="https://www.facebook.com/Soul2Soulz" target="_blank" rel="noopener noreferrer">
            <div className="flex flex-row items-center md:justify-start  p-5 text-black underline-none">
              <div className="w-20">
                <img src={Facebook} alt="" />
              </div>
              <div className="pl-4">
                <div className="text-2xl s2s-text">Facebook</div>
                <div className="s2s-text">Soul2Soulz</div>
              </div>
            </div>
          </a>


          {/* ********* */}
          {/* Instagram */}
          {/* ********* */}
          <a className="underline-none" href="https://www.instagram.com/soul_2_soulz/" target="_blank" rel="noopener noreferrer">
            <div className="flex flex-row items-center md:justify-start  p-5 text-black underline-none">
              <div className="w-20">
                <img src={Instagram} alt="" />
              </div>
              <div className="pl-4">
                <div className="text-2xl s2s-text">Instagram</div>
                <div className="s2s-text">Soul_2_Soulz</div>
              </div>
            </div>
          </a>


          {/* ******* */}
          {/* Twitter */}
          {/* ******* */}
          <a className="underline-none" href="https://twitter.com/Soul2Soulz" target="_blank" rel="noopener noreferrer">
            <div className="flex flex-row items-center md:justify-start p-5 text-black underline-none">
              <div className="w-20">
                <img src={Twitter} alt="" />
              </div>
              <div className="pl-4">
                <div className="text-2xl s2s-text">Twitter</div>
                <div className="s2s-text">Soul2Soulz</div>
              </div>
            </div>
          </a>

          {/* ********** */}
          {/* Soul2Soulz */}
          {/* ********** */}
          <a className="underline-none" href="https://soul2soulz.com" target="_blank" rel="noopener noreferrer">
            <div className="flex flex-row items-center md:justify-start p-5 text-black underline-none">
              <div className="w-12">
                <img src={logo} alt="" />
              </div>
              <div className="pl-4">
                <div className="text-2xl s2s-text">Soul2Soulz.com</div>
              </div>
            </div>
          </a>

        </div>
      </div>
    </div>
  );
};

export default Contact;
