import React, { useEffect } from "react";
import enlighten from "../images/Enlighten.jpeg";
import engage from "../images/Engage.jpeg";
import energize from "../images/Energize.jpeg";
import empower from "../images/Empower.jpg";

const Mission = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="pt-32 pb-3 z-0 flex flex-col justify-center goldbg">
      <div className="text-5xl lg:text-6xl s2s-header m-auto">Mission</div>
      <div className="md:py-4">

        {/* ***************** */}
        {/* Mission Statement */}
        {/* ***************** */}
        <div className="py-4 flex justify-center">
          <div className="w-3/4 text-center s2s-text text-xl md:text-2xl lg:3xl">
            Soul2Soulz, Inc offers underserved communities a unique set of
            motivational tools that engage, enlighten, energize, and empower
            youth to live up to their full potential.
          </div>
        </div>

        {/* ****** */}
        {/* Engage */}
        {/* ****** */}
        <div className="flex justify-center flex-col w-full">
          <div className="px-4 lg:pb-0 flex flex-col-reverse md:flex-row">
            <div className="p-5 md:w-1/2 text-left md:text-right self-center pb-4">
              <div className="text-xl lg:text-3xl s2s-text">Engage</div>
              <div className="text-lg lg:text-2xl py-2 s2s-text">
                Youth Development
              </div>
              <div className="text-sm md:text-lg lg:text-xl s2s-text">
                To support young people’s ability to reach their full potential
                and become positive productive adults in our society. We provide
                a range of programs in Social-Emotional Learning, Leadership
                Development & Civic Engagement, Career Preparation,
                Education/Student Support Services, College Preparation & Tours.
              </div>
            </div>
            <div className="md:w-1/2 flex justify-center">
              <div className="p-2 flex items-center">
                <img className="mission-picture-padding" src={engage} alt="" />
              </div>
            </div>
          </div>

          {/* ********* */}
          {/* Enlighten */}
          {/* ********* */}

          <div className="px-4 lg:pb-0 flex flex-col-reverse md:flex-row-reverse ">
            <div className="p-5 md:w-1/2 text-left self-center pb-4">
              <div className="text-xl lg:text-3xl s2s-text">Enlighten</div>
              <div className="text-lg lg:text-2xl py-2 s2s-text">
                Education/Student Support Service
              </div>
              <div className="text-sm md:text-lg lg:text-xl s2s-text">
                Focuses on improving academic outcomes for students. Our services
                include the traditional after school tutoring services as well
                as service-learning and experiential learning that helps connect
                the classroom to real life. The tutoring assistance that we
                offer is in small groups or on a one-to-one basis to deepen
                students understanding of the subject matter and form
                connections to their lived experiences.
              </div>
            </div>
            <div className="md:w-1/2 flex justify-center">
              <div className="p-2 flex items-center">
                <img
                  className="mission-picture-padding"
                  src={enlighten}
                  alt=""
                />
              </div>
            </div>
          </div>

          {/* ******** */}
          {/* Energize */}
          {/* ******** */}

          <div className="px-4 lg:pb-0 flex flex-col-reverse md:flex-row ">
            <div className="p-5 md:w-1/2 text-left md:text-right self-center pb-4">
              <div className="text-xl lg:text-3xl s2s-text">Energize</div>
              <div className="text-lg lg:text-2xl py-2 s2s-text">
                Parent Workshops
              </div>
              <div className="text-sm md:text-lg lg:text-xl s2s-text">
                In order to have a stronger impact on students, Soul2Soulz has
                created workshops to fortify the relationship between parent and
                child to form better communication pathways.
              </div>
            </div>
            <div className="md:w-1/2 flex justify-center">
              <div className="p-2 flex items-center">
                <img
                  className="mission-picture-padding"
                  src={energize}
                  alt=""
                />
              </div>
            </div>
          </div>

          {/* ******* */}
          {/* Empower */}
          {/* ******* */}

          <div className="px-4 lg:pb-0 flex flex-col-reverse md:flex-row-reverse ">
            <div className="p-5 md:w-1/2 text-left self-center pb-4">
              <div className="text-xl lg:text-3xl s2s-text">Empower</div>
              <div className="text-lg lg:text-2xl py-2 s2s-text">
                Teacher Workshops
              </div>
              <div className="text-sm md:text-lg lg:text-xl s2s-text">
                It’s widely known that teacher and student connection is
                imperative in order for students to perform well in the
                classroom. Soul2Soulz has developed workshops that break down
                the barriers between students and teachers in a way that can
                foster meaningful relationships and improve classroom
                management.
              </div>
            </div>
            <div className="md:w-1/2 flex justify-center">
              <div className="p-2 flex items-center">
                <img className="mission-picture-padding" src={empower} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
