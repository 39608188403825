import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import s2svideo from "../videos/S2SlogoAnimation.mp4";
import slide1 from "../images/S2SMission.jpg";
import slide2 from "../images/S2SCatchPhrase.jpg";
import slide3 from "../images/S2SThe4Es.jpg";
import slide4 from "../images/S2SYouthDevelopment.jpg";
import slide5 from "../images/S2SEducation.jpg";
import slide6 from "../images/S2SParentWorkshop.jpg";
import slide7 from "../images/S2STeacherWorkshops.jpg";
import slide8 from "../images/S2SOrganizationChart.jpg";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="flex justify-center text-center flex-col w-full pt-32 pb-10 goldbg">
      <div className="text-5xl lg:text-6xl s2s-header">Soul2Soulz</div>
      <div className="text-lg lg:text-2xl w-1/2 self-center pb-4 s2s-text">
        Empowering Lives by Engaging Souls
      </div>

      <Carousel
      interval={8000}
       >
        <Carousel.Item className="home-carousel">
          <video
            autoPlay muted playsInline
            className="m-auto d-block justify-content-center h-full"
          >
            <source src={s2svideo} type="video/mp4"></source>
          </video>
        </Carousel.Item>
        <Carousel.Item className="home-carousel">
          <img
            className="m-auto d-block justify-content-center h-full"
            src={slide1}
            alt="First slide"
          />
        </Carousel.Item>

        <Carousel.Item className="home-carousel">
          <img
            className="m-auto d-block justify-content-center h-full"
            src={slide2}
            alt="First slide"
          />
        </Carousel.Item>

        <Carousel.Item className="home-carousel">
          <img
            className="m-auto d-block justify-content-center h-full"
            src={slide3}
            alt="First slide"
          />
        </Carousel.Item>

        <Carousel.Item className="home-carousel">
          <img
            className="m-auto d-block justify-content-center h-full"
            src={slide4}
            alt="First slide"
          />
        </Carousel.Item>

        <Carousel.Item className="home-carousel">
          <img
            className="m-auto d-block justify-content-center h-full"
            src={slide5}
            alt="First slide"
          />
        </Carousel.Item>

        <Carousel.Item className="home-carousel">
          <img
            className="m-auto d-block justify-content-center h-full"
            src={slide6}
            alt="First slide"
          />
        </Carousel.Item>

        <Carousel.Item className="home-carousel">
          <img
            className="m-auto d-block justify-content-center h-full"
            src={slide7}
            alt="First slide"
          />
        </Carousel.Item>

        <Carousel.Item className="home-carousel">
          <img
            className="m-auto d-block justify-content-center h-full"
            src={slide8}
            alt="First slide"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Home;
