import React from "react";
import Burger from "./Burger";
import logo from "../images/Icon.png";
import { NavLink } from "react-router-dom";

export default function Navbar() {
  return (
    <div
    className="flex flex-row align-items-center  items-center bg-black p-4 justify-between fixed w-screen z-50"
      id="navbar"
    >
      
      <div className="lg:pl-12">
        <NavLink className="underline-none" exact to={"/"}>
          <div className="flex flex-row items-end">
            <div className="flex flex-row items-center gold">
              <div className="flex flex-col items-center content-center pr-2">
              <div className="text-xs s2s-text leading-tight">Soul</div>
              <div className="text-xs s2s-text leading-none">2</div>
              <div className="text-xs s2s-text leading-tight">Soulz</div>
              </div>
          <div><img className="h-12 w-10" src={logo} alt="logo" /></div>
          
          </div>
          <div className="pl-2 flex flex-col self-center">
          <div className="gold text-xxs s2s-text">Empowering Lives</div>
          <div className="gold underline-none text-xxs s2s-text">by Engaging Souls</div>
          </div>
          </div>
        </NavLink>
      </div>

      <div className="align-items-center hidden xl:flex xl:flex-row lg:flex lg:flex-row lg:pr-8">
        <div>
        <NavLink className="gold pr-10 nav-title s2s-alt font-bold" exact to={"/"}>
            Home
          </NavLink>
        </div>

        <div className="dropdown">
          <div className="dropbtn gold pr-8 nav-title s2s-alt font-bold">About Us</div>
          <div className="dropdown-content rounded">
          <div><NavLink className="gold nav-title s2s-alt text-center border-b-2 border-black font-bold" exact to={"/about"}>
            What We Do
          </NavLink></div>
          <div><NavLink className="gold nav-title s2s-alt text-center border-b-2 border-black font-bold" exact to={"/ceo"}>
            CEO
          </NavLink></div>
          <div><NavLink className="gold nav-title s2s-alt text-center border-b-2 border-black font-bold" exact to={"/board"}>
            Board of Directors
          </NavLink></div>
          <div><NavLink className="gold nav-title s2s-alt text-center border-b-2 border-black font-bold" exact to={"/staff"}>
            Staff
          </NavLink></div>
          <div><a target="_blank" rel="noopener noreferrer" exact href="http://soul2soulz.com/" className="gold nav-title s2s-alt text-center font-bold">
          Soul2Soulz.com
          </a></div>
          </div>
        </div>

        <div>
          <NavLink className="gold pr-8 nav-title s2s-alt font-bold" exact to={"/mission"}>
            Mission
          </NavLink>
        </div>

        <div className="dropdown">
          <div className="dropbtn gold pr-8 nav-title s2s-alt font-bold">Programs</div>
          <div className="dropdown-content rounded">
          <div><NavLink className="gold pr-10 nav-title s2s-alt text-center border-b-2 border-black font-bold" exact to={"/development"}>
          Youth Development
          </NavLink></div>
          <div><NavLink className="gold pr-10 nav-title s2s-alt text-center border-b-2 border-black font-bold"  exact to={"/education"}>
          Education
          </NavLink></div>
          <div><NavLink className="gold pr-10 nav-title s2s-alt text-center border-b-2 border-black font-bold" exact to={"/parent-workshop"}>
          Parent Workshops
          </NavLink></div>
          <div><NavLink className="gold pr-10 nav-title s2s-alt text-center font-bold" exact to={"/teacher-workshop"}>
          Teacher Workshops
          </NavLink></div>
          </div>
        </div>

        <div>
          <NavLink className="gold pr-8 nav-title s2s-alt font-bold" exact to={"/contact"}>
            Contact
          </NavLink>
        </div>

        <div>
          <NavLink className="gold nav-title s2s-alt font-bold" exact to={"/donate"}>
            Donate
          </NavLink>
        </div>

      </div>
      <div className="lg:hidden flex align-items-center">
      <Burger />
      </div>
    </div>
  );
}
