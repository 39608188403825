import React, { useEffect } from "react";

const Development = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="pt-32 page-padding flex flex-col pb-10 goldbg">
      <div className="text-4xl lg:text-6xl self-center s2s-header text-center pb-4">
        Youth Development
      </div>
      <div className="pb-4 s2s-text lg:text-lg">
        Soul2Soulz, Inc offers a variety of Youth Development Programs and
        Services to support young people’s ability to reach their full potential
        and become positive productive adults in our society. We provide a range
        of programs in Social-Emotional Learning, Leadership Development & Civic
        Engagement, Career Preparation, Education/Student Support Services,
        College Preparation & Tours.
      </div>
      <div className="py-2 s2s-text text-xl font-bold">Social-Emotional Learning </div>
      <div className="pb-4 s2s-text">
        Soul2Soulz, Inc’s social-emotional learning activities, focuses on
        self-discovery through thought provoking role-plays and peer-to-peer
        discussions utilizing issue-based films with lessons that follow the
        Collaborative for Academic, Social and Emotional Learning (CASEL), the
        Department of Education-approved standard for Social-Emotional Learning
        (SEL).
      </div>
      <div className="py-2 s2s-text text-xl font-bold">
        Leadership Development & Civic Engagement
      </div>
      <div className="pb-2 s2s-text">
        Soul2Soulz, Inc’s leadership development activities are based on the
        belief that effective leaders know themselves and are able to connect
        with others. Our service-learning programs focus on helping youth
        realize that they can be leaders through service to their community. The
        life skills programs that we offer encourages youth to reflect on their
        personal challenges and engage in collaborative problem-solving with
        skilled facilitators and peers. Our programs in leadership, civic
        engagement, and life skills promote the development of social
        competencies, job readiness, and personal integrity.
      </div>
      <div className="pb-2 s2s-text">
        Soul2Soulz, Inc’s Project Youth Impact is a Youth Facilitation training
        program, students are trained to lead peer-to-peer groups focused on
        personal development and the positive influence youth have on peers
        ability to make healthy choices. Youth also provide service projects in
        the community.
      </div>
      <div className="pb-4 s2s-text">
        Soul2Soulz, Inc’s Adopt-A-Grandparent program creates partnerships with
        nursing homes, schools, and volunteer youth to match youth with a
        “Grandparent.” The goal is to develop positive relationships through
        intergenerational discussions and provide elders with minimum family
        contact, the joy of connection with a young person. Students can learn
        from the past in order to improve their sense of self and elders can
        feel connected to this generation.
      </div>
      <div className="py-2 s2s-text text-xl font-bold">Restorative Justice</div>
      <div className="pb-2 s2s-text">
        Soul2Soulz, Inc’s Restorative Justice program focuses on dispute
        resolution and repairing the harms caused by violating the rights of
        others. Our approach focuses on giving youth the skills they need to
        navigate the violence and oppression that often surrounds them in their
        daily lives through improving their communication skills, empathy, and
        ability to be reflective.
      </div>
      <div>
        <div>
          <img src="" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Development;
