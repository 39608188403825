import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Page404 = ({location}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
      });
    return (
        <div className="pt-32 bg-black">
            <div className="gold py-10 s2s-header text-3xl text-center">404 No Match Found</div>
            <div className="text-center pb-5 text-lg"><Link to="/" exact>Return Home</Link></div>
        </div>
    );
}

export default Page404;