import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'
import Home from './Home'
import About from './About'
import Mission from './Mission'
import Board from './Board'
import Contact from './Contact'
import Donate from './Donate'
import Ceo from './Ceo';
import Staff from './Staff';
import Career from './Career';
import Development from './Development';
import ParentWorkshop from './ParentWorkshop';
import TeacherWorkshop from './TeacherWorkshop';
import Education from './Education';
import Page404 from './Page404'

const Main = () => {
    return (
        <div>
            <Switch>
            <Route exact path="/">
                <Home />
            </Route>

            <Route exact path="/about">
                <About />
            </Route>

            <Route exact path="/mission">
                <Mission />
            </Route>

            <Route exact path="/ceo">
                <Ceo />
            </Route>

            <Route exact path="/staff">
                <Staff />
            </Route>
            
            <Route exact path="/board">
                <Board />
            </Route>

            <Route exact path="/career">
                <Career />
            </Route>

            <Route exact path="/development">
                <Development />
            </Route>
            <Route exact path="/education">
                <Education />
            </Route>

            <Route exact path="/parent-workshop">
                <ParentWorkshop />
            </Route>

            <Route exact path="/teacher-workshop">
                <TeacherWorkshop />
            </Route>

            <Route exact path="/contact">
                <Contact />
            </Route>

            <Route exact path="/donate">
                <Donate />
            </Route>
            <Route exect path="/Page404"component={Page404} />
            <Redirect to="/Page404" />
            </Switch>
        </div>
    );
}

export default Main;