import React, { useEffect } from "react";

const Education = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="pt-32 flex flex-col pb-10 goldbg page-padding">
      <div className="text-5xl lg:text-6xl self-center s2s-header text-center pb-2">
        Education
      </div>
      <div className="s2s-text py-4">
        Soul2Soulz, Inc’s Education and Student Support Services programming
        focuses on improving academic outcomes for students. Our services
        include day and after school tutoring services as well as
        service-learning and experiential learning that helps connect the
        classroom to real life. The tutoring assistance we offer is in small
        groups or on a one-to-one basis to deepen students understanding of the
        subject matter and form connections to their lived experiences.
      </div>
      <div className="s2s-text text-xl py-2 font-bold">College Preparation</div>
      <div className="s2s-text pb-4">
        Soul2Soulz, Inc recognizes that a college education does not determine a
        person’s worth, but every child should be prepared to attend and be
        successful in college. We provide Regents Prep, SAT Prep, Job Search
        assistance, Career Fairs and Workshops, College Search & Application
        assistance, and service learning projects that have been successful in
        helping students attend college. Soul2Soulz, Inc believes that there is
        a need to connect college attendance to career aspirations and
        understand how being a college graduate fits into their vision of self.
      </div>
      <div className="text-xl s2s-text py-2 font-bold">College Tours</div>
      <div className="s2s-text ">
        Soul2Soulz, Inc provides in-person and virtual college tours to immerse
        students in the college life experience. The tour offers discussions
        with college admission officers and college students. A major goal is to
        get high school seniors on the spot acceptance.
      </div>
    </div>
  );
};

export default Education;
